@import "./fonts/stylesheet.css";

body {
  margin: 0;
  font-family: "guildford", sans-serif;
  font-weight: 100;
  background: url("background.webp");
  color: black;
  padding-bottom: 10vh;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.ReactGridGallery_custom-overlay:hover {
  opacity: 0.5;
  background-color: grey;
}

.ReactGridGallery_custom-overlay {
  overflow: hidden;
}

.paintings-title {
  margin-top: 90px;
}

.kikito-gallery-common #ReactGridGallery {
  width: 1600px;
}

.kikito-gallery-concepts #ReactGridGallery {
  width: 1335px;
}

/* first child*/
.kikito-gallery-concepts #ReactGridGallery>div:first-child {
  justify-content: space-between;
  background-color: white;
}


.kikito-gallery-custom #ReactGridGallery {
  width: 1235px;
}

.kikito-gallery-posters #ReactGridGallery {
  width: 1450px;
}



.kikito-gallery-digital-drawings #ReactGridGallery {
  width: 2400px;
}

/* TODO: handle images for React Grid Gallery paintings */
.kikito-gallery-digital-drawings #ReactGridGallery img {
  height: 250px !important;
  cursor: pointer;
}

.kikito-gallery-common #ReactGridGallery img {
  height: 250px !important;
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  :root {
    --img-height: 25vw;
  }

  .kikito-gallery-common #ReactGridGallery img,
  .kikito-gallery-digital-drawings #ReactGridGallery img {
    height: var(--img-height) !important;
    cursor: pointer;
  }

  .kikito-gallery-common #ReactGridGallery {
    width: calc(var(--img-height) * 6.4);
    /* 6,4 */
  }

  .kikito-gallery-digital-drawings #ReactGridGallery {
    width: calc(var(--img-height) * 9.6);
    /* 9,6 */
  }

  .kikito-gallery-custom #ReactGridGallery {
    width: calc(var(--img-height) * 4.9);
  }

  .kikito-gallery-posters #ReactGridGallery {
    width: calc(var(--img-height) * 6);
  }

  .kikito-gallery-concepts #ReactGridGallery {
    width: calc(var(--img-height) * 5.335);
  }

  .nav-button-gallery {
    padding: 4vw !important;
    background-size: 3.5vw !important;
    opacity: 0.5;
  }
}

@media screen and (max-width: 980px) {
  .modelisation-gallery #ReactGridGallery img {
    height: 15vw !important;
  }
}

.modelisation-gallery #ReactGridGallery>div {
  justify-content: center;
}

.ReactGridGallery_tile {
  background-color: black !important;
}

.ReactGridGallery_tile-viewport {
  height: 100% !important;
}

.kikito-gallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.kikito-gallery {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* #ReactGridGallery > div {
  justify-content: space-between;
  padding: 20px;
  row-gap: 20px;
} */

h1 {
  font-family: "lemon-yellow-sun";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 500;
  /* TODO handle responsive font-size for title and there images */
  /* font-size: 3vmin; */
}

p {
  font-size: 18px;
  text-align: justify;
  padding-bottom: 15px;
}

.display-flex-align-items-center {
  display: flex;
  align-items: center;
}

@media (max-width: 980x) {
  .container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 980px) {
  .container {
    min-width: 980px;
    width: 90%;
  }
}

.container {
  max-width: 980px;
  padding-right: 4%;
  padding-left: 4%;
  margin: 0 auto;
  /* Cette propriété auto applique une marge égale à gauche et à droite, centrée horizontalement */
}

#menu {
  font-family: "lemon-yellow-sun", sans-serif;
  padding-top: 25px;
  padding-bottom: 45px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: url("background.webp");
}

#menu ul {
  padding-left: 0px;
}

#menu div,
#menu nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

#menu-logo {
  width: 139px;
  height: 90px;
  object-fit: cover;
}

#menu a {
  color: black;
  font-weight: 300;
  transition: all 0.5s ease-in-out;
}

#menu a:hover {
  color: rgb(131 103 99);
}

.custom-overlay__caption {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  color: rgba(216, 215, 215, 0.813);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button-gallery {
  border: none;
  padding: 40px 30px;
  cursor: pointer;
  opacity: 0.5;
}

.nav-button-gallery-prev {
  background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) no-repeat center;
}

.nav-button-gallery-next {
  background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+) no-repeat center;
}

.nav-button-gallery:hover {
  opacity: 1;
}

.legend {
  margin-top: 0;
  font-size: medium;
  margin-bottom: 35px;
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 5vw !important;
  }

  h1 img {
    height: 6vw !important;
    width: 6vw !important;
  }

  p {
    font-size: 2.5vmin !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare-image-loader {
  height: 100%;
}

.compare-image-loader * {
  height: 100%;
}

.card__loader {
  width: 250px;
  background: #ffffff00;
}


.image__loader {
  width: 100%;
  background: #ffffff00;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.child__image__loader {
  background: linear-gradient(110deg, #252525 8%, #3a3a3a 18%, #252525 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.compare-image-loader .child__image__loader {
  height: 100%;
  border-radius: 5px;
}

.card__loader .child__image__loader {
  height: 250px;
}

.contact {
  width: 16%;
}

.contact a {
  color: black;
}

.contact-icon-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.05rem;
  /* Assuming mt-05 corresponds to 0.05rem */
}

.contact-icon {
  width: 7vmin;
  height: 7vmin;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.contact-icon.linkedin {
  background-image: url('/public/logos/linkedin.webp');
  background-size: 135%;
}

.contact-icon.gmail {
  background-image: url('/public/logos/gmail.webp');
}

.contact-icon.instagram {
  background-image: url('/public/logos/instagram.webp');
}