/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 23, 2024 */

@font-face {
  font-family: "guildford";
  src: url("red_rooster_collection_-_guildfordpro-webfont.woff2") format("woff2"),
    url("red_rooster_collection_-_guildfordpro-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "guildford";
  src: url("red_rooster_collection_-_guildfordpro-bold_1_1-webfont.woff2") format("woff2"),
    url("red_rooster_collection_-_guildfordpro-bold_1_1-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "guildford";
  src: url("red_rooster_collection_-_guildfordpro-italic-webfont.woff2") format("woff2"),
    url("red_rooster_collection_-_guildfordpro-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "guildford";
  src: url("red_rooster_collection_-_guildfordpro-medium-webfont.woff2") format("woff2"),
    url("red_rooster_collection_-_guildfordpro-medium-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "lemon-yellow-sun";
  src: url("DK Lemon Yellow Sun.woff2") format("woff2");
}